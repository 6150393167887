<script setup lang="ts">
const props = defineProps({
  originalCols: {
    type: Number,
    default: 2,
  },
  rl: {
    type: String,
    default: "l",
  },
})
const route = useRoute()
</script>

<template>
  <div v-if="props.originalCols === 2 && route.path === '/'" class="relative">
    <div v-if="rl === 'l'" class="relative py-20 md:py-36 px-6 md:px-0 bg-peach-700 text-peach-100 text-center">
      <div class="max-w-2xl mx-auto z-10">
        <div class="font-serif text-burgundy text-3xl md:text-6xl uppercase">
          <strong>
            Holder til i Lillestrøm
          </strong>
        </div>
        <div class="pt-4">... og reiser overalt i Norge.</div>
      </div>
    </div>
    <div v-else class="py-20 md:py-36 px-6 md:px-0 bg-burgundy-300 text-center">
      <div class="max-w-3xl mx-auto">
        <div class="flex items-center space-x-8">
          <div class="text-left">
            <div class="pb-8">
              <MediaFFLogo mode="primary" width="300" />
            </div>
            <div class="font-serif text-6xl md:text-9xl uppercase leading text-burgundy">
              Årets fotograf
            </div>
            <div class="text-burgundy text-3xl md:pt-4">
              Oslo Fotograflaug, 2022
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
