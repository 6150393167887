<script setup lang="ts">
const mainStore = useMainStore()
const { filterBlogPosts } = mainStore
const props = defineProps({
  limit: {
    type: String,
    default: "all"
  },
  display: {
    type: String,
    default: "grid"
  },
  category: {
    type: String,
    default: ""
  }
})
const posts = filterBlogPosts(props.limit, props.category)
</script>

<template>
  <div class="max-w-5xl py-20 px-6 mx-auto w-full @container ">
    <div :class="{ 'grid grid-cols-1 gap-8 content-start @md:auto-rows-fr @md:grid-cols-3': props.display === 'grid', 'flex flex-col space-y-8': props.display === 'list' }">
      <BlogItem v-for="post in posts" :key="post.slug" :post="post" />
    </div>
  </div>
</template>
