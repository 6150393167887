<script setup lang="ts">
  const props = defineProps({
    data: {
      type: Object,
      default: () => {},
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  })

  const py = 'py-0 md:py-20'
</script>

<template>
  <div v-for="(item, i) in data" :key="`page_builder_item_${i}`" class="relative">
    <PageBuilderColumns
      v-if="item._type === 'pageBuilderColumns'"
      :data="item.columns"
    />
    <PageBuilderReference
      v-else-if="item._type === 'pageBuilderReference'"
      :data="item.references"
      :class="py"
    />
    <MediaBaseFigure
      v-else-if="item._type === 'image' && item?.asset"
      :image="item"
      :alt="item.alt ?? ''"
      :asset="item?.asset"
      :key="item.key"
      :isSticky="isSticky"
    />
    <PageBuilderContent
      v-else-if="item._type === 'pageBuilderContent' || item._type === 'content'"
      :data="item"
      :class="py"
    />
    <PageBuilderProduct
      v-else-if="item._type === 'pageBuilderProduct'"
      :data="item.products"
      :class="py"
    />
    <PageBuilderForm
      v-else-if="item._type === 'pageBuilderForm'"
      :data="item"
      class="mb-20"
    />

    <PageBuilderBlogPosts
      v-else-if="item._type === 'pageBuilderBlogs'"
      :limit="item.limit"
      :category="item.category"
      display="grid"
    />

    <PageBuilderIframe
      v-else-if="item._type === 'pageBuilderIframe'"
      :data="item"
      :class="py"
    />
    <Gallery v-else-if="item._type === 'gallery'" :data="item" :class="py" />
    <FAQ v-else-if="item._type === 'pageBuilderFAQ'" :data="item.questions" />
    <ElementTextBox
      v-else-if="item._type === 'textbox'"
      :data="item"
      :idx="item.key"
      class="sticky top-0 self-start"
      :class="py"
    />
    <p v-else class="text-sm text-gray-500">
        Component not found: <strong>{{ item._type }}</strong>
    </p>
  </div>
</template>
