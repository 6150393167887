<script setup lang="ts">
import type { PropType } from 'vue';
import type { Service } from '~~/types';

  const props = defineProps({
    data: {
      type: Array as PropType<Service[]>,
      default: () => []
    }
  })

  const columns = computed(() => props.data.length)
</script>

<template>
  <div class="container mx-auto p-4">
    <div v-if="columns === 1" class="text-xl mb-3 font-bold @sm:text-2xl @sm:tracking-tight">
        {{ props.data[0]?.product.title }}
    </div>
    <div class="grid ring-2 bg-neutral-100 ring-burgundy-300 divide-y-2 md:divide-x-2 md:divide-y-0 divide-burgundy-300" :class="{'grid-cols-1 md:grid-cols-3': columns === 3, 'grid-cols-1 md:grid-cols-2': columns === 2, 'grid-cols-1': columns === 1}">
      <PageBuilderProductItem 
        v-for="(item, i) in props.data" 
        :key="`page_builder_product_item_${i}`" 
        :service="item?.product"
        :is-single="columns === 1"
      />
    </div>
  </div>
</template>
