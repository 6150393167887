<script setup lang="ts">
import { useBodyOverflow } from '~/composables/use-body-overflow'
import { useMainStore } from '~/stores/MainStore'
import type { ImageType } from '~~/types'
const props = defineProps({
  images: {
    type: Array<ImageType>,
    default: () => []
  },
  id: {
    type: String,
    default: ""
  }
})

const emit = defineEmits(["media-click"])

// consider moving this up level - to gallery component
const fullscreenGalleryIsOpen = ref(false)
const showingCarousel = ref(false)
const currentSlideIndex = ref<number>(0)
const slideIndex = computed(() => currentSlideIndex.value)
const store = useMainStore()
const openFullscreenGallery = (index: number) => {
  currentSlideIndex.value = index
  // fullscreenGalleryIsOpen.value = true
  showingCarousel.value = true
  // useBodyOverflow("hidden")
  // emit("media-click", index)
}

// scroll to image on page after swiper has a new index?
const closeFullscreenGallery = () => {
  fullscreenGalleryIsOpen.value = false
  // currentSlideIndex.value = 0
  useBodyOverflow("auto")
}


</script>

<template>
  <div class="px-0">
    <div class="max-h-screen w-full" v-if="showingCarousel">
      <Transition name="fade" mode="in-out">
        <GalleryCarousel :images="images" :start-index="currentSlideIndex" />
      </Transition>
    </div>
    <div v-else class="md:columns-3 md:gap-4">
      <div class="md:mb-4" v-for="(img, i) in images" :key="`page_builder_item_${i}`">
        <MediaBaseFigure 
          :alt="img.alt" 
          :image="img" 
          class="cursor-pointer" 
          use-hotspot
          @click="openFullscreenGallery(i)"
          @current-slide="(v: any) => (currentSlideIndex = v)"
          data-aos="zoom-in" 
          data-duration="1000"
          :data-aos-delay="i * 100"
        />
      </div>
    </div>
    <!-- <Transition name="fade" mode="in-out">
      <GalleryFullscreen :key="`gallery_fullscreen_masonry_${props.id}`" :is-open="fullscreenGalleryIsOpen"
        :slide-index="slideIndex" :images="images" @current-slide="(v: any) => (currentSlideIndex = v)"
        @close="closeFullscreenGallery" />
    </Transition> -->
  </div>
</template>
