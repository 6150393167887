<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
})
const bgColorHex = computed(() => {
  return props.data && props.data.bgColor && props.data.bgColor.value
    ? props.data.bgColor.value
    : "#fff"
})
const fgColorHex = computed(() => {
  return props.data && props.data.fgColor && props.data.fgColor.value
    ? props.data.fgColor.value
    : "#777"
})
const fgStyle = computed(() =>
  bgColorHex.value && fgColorHex.value
    ? `background-color: ${bgColorHex.value}; color: ${fgColorHex.value};`
    : ""
)

// :style="fgStyle"
</script>

<template>
  <div class="p-4 pb-12 md:p-8 md:pb-8 w-full md:w-auto sticky top-0 z-10">
    <ElementContent :blocks="data.body" />
  </div>
</template>
