<script setup lang="ts">
import type { ImageType } from '~~/types'
const props = defineProps({
  images: {
    type: Array<ImageType>,
    default: () => [] as ImageType[]
  },
  idx: {
    type: Number,
    default: 0
  },
  title: {
    type: String,
    default: ""
  }
})
</script>

<template>
  <div class="md:grid md:gap-2">
    <div
      v-for="(img, i) in props.images"
      :key="`gallery_images_image_${i}`"
      :class="{
        'justify-start': idx === 0,
        'justify-end': idx > 0
      }"
      class="flex img-zipzag"
      data-aos="zoom-in"
      data-duration="1000"
      :data-aos-delay="i * 100"

    >
      <MediaBaseFigure :image="img" class="max-w-[600px]" :data-rellax-speed="-1" />
    </div>
  </div>
</template>
