<script setup lang="ts">
const props = defineProps({
  service: {
    type: Object as any,
    default: () => {},
  },
  isSingle: {
    type: Boolean,
    default: false,
  },
  mostPopular: {
    type: Boolean,
    default: false,
  },
})
const amount = computed(() => {
  const number = props.service.amount
  return number ?  number.toLocaleString("nb-NO") : ""
})
const header = computed(() => {
  return props.service.title.split(' - ')[0]
})
const title = computed(() => {
  return props.service.title.split(' - ')[1]
})
</script>

<template>
  <div class="@container w-full">
    <div class="flex flex-col @min-[675px]:flex-row h-full">
      <div class="@min-[675px]:pr-12 flex-1 space-y-4 p-8">
        <div v-if="!props.isSingle" class="pb-2">
          <div class="text-xl font-semibold">
            {{ header }}
          </div>
          <div class="text-3xl font-bold">
            {{ title }}
          </div>

        </div>
        <div v-if="props.service.description" class="text-base text-gray-500">
          {{ props.service.description }}
        </div>
        <div class="flex-1">
          <ul
            role="list"
            class="space-y-5"
            :class="{
              ' lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0':
                props.isSingle,
            }"
          >
            <li
              v-for="feature in service.included"
              :key="feature"
              class="flex items-center"
            >
              <div class="flex-shrink-0">
                <span class="text-burgundy-300" aria-hidden="true">●</span>
              </div>
              <p class="ml-3 text-sm text-neutral-800">{{ feature }}</p>
            </li>
          </ul>
          
        </div>
        <!-- <pre>{{ props.service }}</pre> -->
      </div>
      <div class="@min-[675px]:w-[220px] @min-[675px]:border-l border-burgundy-300 @min-[675px]:bg-burgundy-100 flex flex-col justify-center items-center p-6" :class="{'py-3': props.isSingle}">
        {{ amount }},-
        <span class="text-neutral-700 text-xs">inkludert mva.</span>
        <UButton
          v-if="!props.isSingle"
          class="mt-4"
          variant="ghost"
          size="lg"
          to="/kontakt"
          label="Velg pakke"
          :ui="{ base: 'px-8 py-3 text-xl'}"
        />
        <div class="mt-4 text-xs italic text-neutral-800 text-center">
          <ElementContent :blocks="service.terms" class="-mb-6" />
        </div>
      </div>
    </div>
  </div>
</template>
