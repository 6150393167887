<script setup lang="ts">
import type { PropType } from 'vue'
import type { Reference } from '~~/types'

  const props = defineProps({
  data: {
    type: Array as PropType<Reference[]>,
    default: () => [],
  },
})

const animationDelay = (index: number) => {
  var delay = 0
  delay = delay + index * 200
  return delay
}

// should be refactored
const slug = (meta: any) => {
  if (meta && meta.articleType === "post") {
    return `/blog/${meta.slug}`
  }
  return `/${meta.slug}`
}
</script>

<template>
  <div class="px-2 md:px-10 lg:px-20 py-10">
    <div class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-10 md:gap-10 items-stretch">
      <div
        v-for="(item, i) in props.data"
        :key="`page_reference_${item?._key}`"
        class="relative"
      >
      <div 
        class="p-4 border-2 max-w-[330px] shadow-xl md:shadow-none mx-auto border-transparent transition-all duration-300 ease-in-out bg-gray-50 hover:border-burgundy-300 active:border-burgundy-200"
        data-aos="fade-up"
        :data-aos-delay="animationDelay(i)"
        data-aos-duration="1000"
      >
        <NuxtLink :to="slug(item?.meta)" class="relative">
          <MediaBaseFigure
            v-if="item?.meta.mainImage"
            :image="item?.meta.mainImage"
            :alt="item?.meta.mainImage?.alt ?? ''"
            :width="300"
          />
          <div>
            <div
              class="pt-6 text-sm text-grey break-words flex-shrink text-burgundy-600"
            >
              {{ item?.meta.title }}
            </div>
            <div
              class="text-xl md:text-lg text-neutral-800 break-words max-h-24 overflow-hidden text-ellipsis"
            >
              {{ item?.meta.description }}
            </div>
          </div>
        </NuxtLink>
      </div>
      </div>
    </div>
  </div>
</template>
