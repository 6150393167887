<script setup>
const props = defineProps({
  images: {
    type: Array,
    default: () => []
  }
})
</script>
<template>
  <div class="grid md:grid-cols-3">
    <div v-for="(img, i) in props.images" :key="`gallery_grid_img_${i}`" class="md:p-8">
      <MediaBaseFigure :image="img" />
    </div>
  </div>
</template>
