<script setup lang="ts">
import type { ImageType } from '~~/types';

const props = defineProps({
  images: {
    type: Array as PropType<ImageType[]>,
    default: () => []
  },
  startIndex: {
    type: Number,
    default: 0
  }
})
</script>

<template>
  <UCarousel
      ref="carouselRef"
      v-slot="{ item }"
      prev-icon="i-lucide-chevron-left"
      next-icon="i-lucide-chevron-right"
      :items="images"
      dots
      :autoplay="true"
      :start-index="props.startIndex"
      :auto-height="true"
      :auto-play="true"
      :drag-threshold="5"
      class="w-full h-full flex items-center"
      :ui="{ dot: 'size-2', dots: 'gap-2'}"
    >
    <div class="relative flex-1 flex w-full h-full max-h-screen max-w-full items-center justify-center">
      <MediaBaseFigure
        :image="item"
        :alt="item?.alt"
        use-hotspot
        object="contain"
        class="cursor-grab max-h-screen"
      />
    </div>
  </UCarousel>
</template>
