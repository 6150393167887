<script setup lang="ts">
import type { ImageType } from '~~/types';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  idx: {
    type: Number,
    default: 0,
  },
})

const images = computed(() => props.data.images as ImageType[])
</script>

<template>
  <div
    class=""
    :class="{
      'bg-peachy bg-masonry-linear': props.data.display === 'masonry',
    }"
  >
    <div
      class="mx-auto"
      :class="{ container: props.data.display !== 'carousel' }"
    >
      <GalleryZigzag
        v-if="props.data.display === 'zigzag'"
        :images="images"
        :idx="props.idx"
        :title="data?.title"
      />
      <GalleryMasonry
        v-else-if="props.data.display === 'masonry'"
        :images="images"
        :idx="props.idx"
      />
      <GalleryGrid
        v-else-if="props.data.display === 'grid'"
        :images="images"
        :idx="props.idx"
      />
      <GalleryCarousel
        v-else-if="props.data.display === 'carousel'"
        :images="images"
      />
      <div v-else class="p-8">
        Gallery component not found.
        <pre>{{ props.data.display }}</pre>
      </div>
    </div>
  </div>
</template>
