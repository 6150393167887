<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object,
    default: () => { },
  },
})

const originalCols = computed(() => props.data && props.data.length)

const rl = computed(() =>
  props.data[0].items[0]._type === "photo" ? "l" : "r"
)
const columnType = (column: any) => {
  return column.items && column.items.length > 0 ? column.items[0]._type : ""
}
const columnIsImage = (column: any) => {
  return !!(
    column.items &&
    column.items.length &&
    ["photo", "image"].includes(column.items[0]._type)
  )
}

// original columns have 3, new design only allows 2
// allow all columns by removing before ?
const columnData = computed(() => {
  // return props.data // ? props.data.slice(0, 2) : []
  return props.data ? props.data.slice(0, 2) : []
})

const columnCount = computed(() => columnData.value.length || 0)
</script>

<template>
  <div class="relative ">
    
    <PageBuilderPosters :original-cols="originalCols" :rl="rl" />

    <div class="relative max-w-6xl mx-auto">
      <div class="relative flex flex-col flex-col-reverse justify-start md:grid md:py-32 md:items-start md:self-start md: content-start gap-6" :class="`md:grid-cols-${columnCount}`">
        <div v-for="(column, c) in columnData" :key="`columns_column_${column._id}`" class="relative">
          <MediaBaseFigure 
            v-if="columnIsImage(column)" 
            class="max-w-[500px]"
            :image="column.items[0]" :alt="column.items[0].alt ?? ''" 
            :key="`column_item_${column.items[0].key}`"
            use-hotspot
            data-aos="zoom-in"
            data-aos-duration="1000"
            width="600px" />
          <PageBuilder v-else :data="column.items" :isSticky="columnIsImage(column)" />
        </div>
      </div>
    </div>
  </div>
</template>
