<script setup lang="ts">
import type { PropType } from 'vue';
import type { BlogPost } from '~~/types';

  const props = defineProps({
    post: {
      type: Object as PropType<BlogPost>,
      default: () => {},
    },
    category: {
      type: String,
      default: ""
    }
  })
</script>

<template>
  <article class="relative @container/item ring-1 ring-peach-200 bg-peach-50 rounded-sm overflow-hidden">
    <div class="flex flex-col items-start @md/item:flex-row">
      <div class="relative w-full max-w-sm @md/item:max-h-full">
        <NuxtLink :to="`/blog/${post.slug.current}`">
          <MediaBaseFigure
            :image="post.mainImage"
            :alt="post.mainImage.alt"
            :width="640"
            class="aspect-[16/9] w-full rounded-2xl bg-neutral-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
          />
        </NuxtLink>
      </div>
      <div class="p-2 flex-1 flex flex-col justify-stretch">
        <NuxtLink :to="`/blog/${post.slug.current}`" class="flex-1 flex flex-col-reverse justify-reverse">
          <div class="group relative p-2 @md:p-6">
            <h3
              class="mt-4 text-lg font-semibold leading-6 text-neutral-900 group-hover:text-neutral-600"
            >
              {{ post.title }}
            </h3>
            <p class="mt-5 text-sm flex-1 leading-6 text-neutral-600 line-clamp-3">
              {{ post.description }}
            </p>
          </div>
          <!-- <Author :author="post.author" /> -->
          <div
            v-if="!props.category"
            class="mt-4 flex items-center gap-x-4 text-2xs @md/item:relative @md:ml-6 right-2 top-1 z-10"
          >
            <span
              class="relative z-10 rounded-full bg-peach-100 pt-1 pb-1.5 px-2 font-medium text-peach-600"
              >{{ post.category.title }}
            </span>
          </div>
        </NuxtLink>
      </div>
    </div>
  </article>
</template>
