<script setup lang="ts">
import type { FormSubmitEvent, FormError } from "#ui/types"
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const router = useRouter()

type State = { string: any }
type FieldOptions = {
  _key: string
  title: string
  value: string
  _type: string
  disabled: boolean
}

type Field = {
  _type: string
  name: string
  label: string
  _key: string
  type: string
  required?: boolean
  options: [FieldOptions]
}

const formState: State = reactive({} as State)
const requiredFields: Array<string> = reactive([])
// add to state and required fields
onMounted(() => {
  props.data.rows.forEach((row: any) => {
    row.fields.forEach((field: Field) => {
      formState[field.name as keyof State] = undefined
      if (field.required) {
        requiredFields.push(field.name)
      }
    })
  })
})

const validate = (requiredFields: any): FormError[] => {
  const errors: FormError[] = []
  for (const key in requiredFields) {
    if (!formState[key as keyof State]) errors.push({ name: key, message: "Må fylles ut" })
  }
  return errors
}

const loadingState = ref(false)

const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
const formResponse = ref(null)
async function onSubmit(event: FormSubmitEvent<any>) {
  loadingState.value = true
  const formData = {
    "form-name": props.data.unique_name,
    ...formState
  }
  console.log("formData encoded", encode(formData))
  fetch("/takk", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode(formData)
  })
    .then(res => {
      loadingState.value = false
      router.replace("/takk")
    })
    .catch(e => (formResponse.value = e.message))
}
</script>

<template>
  <div id="form" class="max-w-2xl w-full mx-auto px-10 py-6 text-center ring-1 ring-burgundy-200 bg-peach-100 rounded">
    <UForm
      :validate="validate"
      :state="formState"
      :name="props.data.unique_name"
      class="space-y-0"
      method="post"
      :action="`/${props.data.actionUrl}`"
      enctype="application/x-www-form-urlencoded"
      data-netlify="true"
      netlify-honeypot="bot-field"
      @submit="onSubmit"
    >
      <input type="hidden" name="form-name" :value="props.data.unique_name" />
      <div
        v-for="row in data.rows"
        :key="`form_row_${row.key}`"
        class="py-4 text-left"
        :class="{ 'md:grid grid-cols-2 space-x-4': row.fields.length > 1 }"
      >
        <div v-for="field in row.fields" :key="`field_row_${row.key}_${field._key}`" class="">
          <FormField :data="field" v-on:keyup="(v) => formState[field.name as keyof State] = v" />
        </div>
      </div>
      <div class="my-4 p-4 rounded-lg border border-neutral-300 bg-neutral-50 text-sm text-left">
        <UCheckbox
          label="Jeg samtykker til betingelser for bruk av tjenesten."
          help="Les personvernerklæringen for detaljer."
        />
        <div class="pl-7 text-sm pt-2">
          <nuxt-link to="/personvern">Les personvernerklæringen</nuxt-link>
        </div>
      </div>
      <div class="py-4">
        <UButton
          v-for="button in props.data.buttons"
          :key="`form_button_${button._key}`"
          :type="button.type"
          :variant="button.display === 'filled' ? `solid` : `outline`"
          :loading="loadingState"
          :ui="{ base: 'px-8 py-3 text-xl'}"
          size="xl"
        >
          {{ button.label }}
        </UButton>
      </div>
      <div ref="formResponse" v-show="formResponse"></div>
    </UForm>
  </div>
</template>
