<script setup lang="ts">
import { watchDebounced } from "@vueuse/core"
import type { FaqItem, ImageType } from "~~/types"

const props = defineProps({
  data: {
    type: Array<FaqItem>,
    default: () => [],
  },
})

const keyword = ref("")

const filteredQuestions = computed(() => {
  return props.data.filter((item) => {
    let fulltext = ""
    if (item.question) {
      fulltext = item.question.toLowerCase()
    }
    if (item.answer) {
      fulltext = fulltext + " " + item.answer[0]?.children[0]?.text.toLowerCase()
    }
    return keyword.value.length > 1 ? fulltext.search(keyword.value.toLowerCase()) > -1 : true
  })
})

watchDebounced(
  keyword,
  () => {
    scrollTop()
  },
  { debounce: 1000, maxWait: 5000 }
)

const scrollTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 80,
      behavior: "smooth",
    })
  }, 61)
}
</script>
<template>
  <div class="relative max-w-6xl mx-auto">
    <div class="sticky top-0 z-50 w-full pt-2 md:px-12 bg-white mx-auto">
      <div class="px-6 pb-1 border-b border-neutral-200 md:border-transparent">
      <UInput icon="i-lucide-search" v-model="keyword" size="xl" variant="outline" placeholder="Søk..." :ui="{base: 'rounded-none w-full', trailing: 'pe-1'}" class="w-full" >
        <template v-if="keyword?.length" #trailing>
          <UButton
            color="neutral"
            variant="link"
            size="sm"
            icon="i-lucide-circle-x"
            aria-label="Clear input"
            @click="keyword = ''"
          />
        </template>
      </UInput>
      </div>
    </div>
    <div class="@container pt-8">
      <div class="text-sm w-full gap-16 @md:columns-2 @lg:columns-2">
        <div
          v-for="(question, q) in filteredQuestions"
          :key="`faq_question_${q}`"
          class="pb-4"
        >
          <FAQItem
            v-if="question._type === 'questions'"
            :question="question.question"
            :answer="question.answer"
          />
          <MediaBaseFigure
            v-else-if="question._type === 'image'"
            :image="question as unknown as ImageType"
            :alt="question.alt"
            :key="question._key"
            :width="640"
          />
        </div>
      </div>
    </div>
    <div v-if="!filteredQuestions.length" class="pt-4">
      <p class="mt-2 text-lg leading-8 text-gray-600">Godt spørsmål!</p>
      <p class="mt-2 text-lg leading-8 text-gray-600">
        Fant ingen svar på det du søker etter her, men send en
        <strong
          ><NuxtLink
            to="mailto:hei@fotostorie.no&subject=Spørsmål til Fotostorie"
            >e-post</NuxtLink
          ></strong
        >
        så får du svar.
      </p>
    </div>
  </div>
</template>
