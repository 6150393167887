<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object,
    default: () => { },
  },
})
const emit = defineEmits(["keyup"])

const fieldInput = ref()
watch(fieldInput, (value) => {
  emit("keyup", value)
})
</script>

<template>
    <UFormField :label="props.data.label" :required="props.data.required">
      <UInput 
        v-if="!['radio', 'textarea'].includes(props.data.type)" 
        size="xl" 
        :placeholder="props.data.placeholder"
        v-model="fieldInput" 
        :required="props.data.required"
        class="w-full"
        :class="{
          'data-hj-suppress': ['name', 'email', 'telephone'].includes(props.data.name)
        }"
        :ui="{ root: 'w-full', base: 'bg-neutral-50'}"
        variant="outline"
      />
      <UTextarea v-else-if="props.data.type === 'textarea'" v-model="fieldInput" :ui="{ root: 'w-full', base: 'bg-neutral-50'}" />
    </UFormField>
</template>
